<mat-card class="category-card {{ active ? 'no-shadow' : 'box-shadow'}}" (click)="active = !active" [ngClass]="{'first-card': firstCard}">
  <div class="card-active" *ngIf="active">
    <div class="overlay"></div>
    <packex-icon icon="check" size="xl" color="white"></packex-icon>
  </div>

  <div class="category-image"  style="background-color: {{ backgroundColor }}">
    <img src="{{ image }}" alt="">
  </div>
  <div class="category-title">
    <mat-card-title>
      <span class="subtitle-2">{{ title }}</span>
    </mat-card-title>
  </div>
</mat-card>
