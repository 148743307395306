import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { User } from '@shared/models/user';
import { switchMap } from 'rxjs';

@Component({
  selector: 'packex-registration-complete-form',
  templateUrl: './registration-complete-form.component.html',
  styleUrls: ['./registration-complete-form.component.scss'],
})
export class RegistrationCompleteFormComponent implements OnInit {
  @Input() loading = false;
  @Output() onSuccess: EventEmitter<User> = new EventEmitter();
  @Output() onFail = new EventEmitter();

  form = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    organization: new FormControl('', Validators.required),
  });

  constructor(
    private userService: UserService,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.userService.user$.subscribe((user) => {
      this.form.get('firstName')?.patchValue(user?.firstName || '');
      this.form.get('lastName')?.patchValue(user?.lastName || '');

      if (user?.organization.name && user?.organization.name !== '-') {
        this.form
          .get('organization')
          ?.patchValue(user?.organization.name as string);
        this.form.get('organization')?.disable();
      }
    });

    this.userService.load().subscribe();
  }

  public submit(): void {
    const formData = this.form ? this.form.value : null;

    if (formData && this.form.valid) {
      this.registrationCompleteFormSubmit();
    }
  }

  public registrationCompleteFormSubmit(): void {
    this.loading = true;

    this.userService
      .updateOrganization({
        name: this.form.get('organization')?.getRawValue() as string,
      })
      .pipe(
        switchMap(() =>
          this.userService.updateUser({
            id: this.userService.user?.id,
            firstName: this.form.get('firstName')?.value as string,
            lastName: this.form.get('lastName')?.value as string,
          }),
        ),
      )
      .subscribe({
        next: () => {
          this.router.navigateByUrl('/').then(() => {
            this.loading = false;
          });
        },
        error: () => (this.loading = false),
      });
  }
}
